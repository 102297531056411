import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-navy text-yellow">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div>
            <h3 className="text-lg font-semibold mb-4">Quick Links</h3>
            <ul className="space-y-2">
              <li><Link to="/" className="hover:text-white transition-colors duration-300">Home</Link></li>
              <li><Link to="/about" className="hover:text-white transition-colors duration-300">About Us</Link></li>
              <li><Link to="/footylab" className="hover:text-white transition-colors duration-300">footyLab</Link></li>
              <li><Link to="/contact" className="hover:text-white transition-colors duration-300">Contact Us</Link></li>
            </ul>
          </div>
          
          <div>
            <h3 className="text-lg font-semibold mb-4">Contact</h3>
            <ul className="space-y-2">
              <li><a href="mailto:info@datarook.com" className="hover:text-white transition-colors duration-300">info@datarook.com</a></li>
              <li>Knoxville, TN</li>
            </ul>
          </div>
          
          <div>
            <h3 className="text-lg font-semibold mb-4">Follow Us</h3>
            <div className="flex space-x-4">
              <a href="https://www.linkedin.com/company/datarook" target="_blank" rel="noopener noreferrer" className="hover:text-white transition-colors duration-300">
                LinkedIn
              </a>
              <a href="https://www.youtube.com/@datarook" target="_blank" rel="noopener noreferrer" className="hover:text-white transition-colors duration-300">
                YouTube
              </a>
              <a href="https://www.instagram.com/datarook_official" target="_blank" rel="noopener noreferrer" className="hover:text-white transition-colors duration-300">
                Instagram
              </a>
            </div>
          </div>
        </div>
        <div className="mt-8 border-t border-yellow-600 pt-8 text-center">
          <p>&copy; 2025 DataRook, Inc. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;