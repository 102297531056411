import React from 'react';
import PageLayout from '../components/templates/PageLayout';
import HeroSection from '../components/organisms/HeroSection';
import Button from '../components/atoms/Button';
import TestimonialSection from '../components/organisms/TestimonialSection';
import { useState, useEffect } from 'react';
import FundraisingBanner from '../components/molecules/FundraisingBanner';
import MethodologySection from '../components/organisms/MethodologySection';
import NewsSection from '../components/organisms/NewsSection';

const Home = () => {
  const [partners, setPartners] = useState([]);
  useEffect(() => {
    // In a real scenario, you might fetch this data from an API
    setPartners([
      { name: 'University of Tennessee', logo: '/utklogo_centered.png', website: 'https://cehhs.utk.edu/' },
      { name: 'One Knoxville SC', logo: '/OneKnoxBadge.png', website: 'https://www.oneknoxsc.com/' },
      { name: 'One Knoxville Collective', logo: '/Collectivelogo.png', website: 'https://www.oneknoxsc.com/collective/' },
      { name: 'Girls Inc. of Tennessee Valley', logo: '/GirlsInc_TNV_logo.png', website: 'https://girlsinctnv.org/' },
      { name: 'East Tennessee Freedom Schools', logo: '/ETSF_logo.png', website: 'https://www.easttnfreedomschools.com/' },
      { name: 'Knoxville Jewish Day School', logo: '/kjds.png', website: 'https://www.kjds.org/' },
      { name: 'Tates School', logo: '/tates_logo.png', website: 'https://www.tatesschool.com/' },
      { name: 'Boys & Girls Club of Tennessee Vallet', logo: '/bgctnv_logo.png', website: 'https://bgctnv.org/' },
    ]);
  }, []);

  return (
    <PageLayout>
      <HeroSection />
      <FundraisingBanner />
      <section className="py-16 bg-white">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold mb-8 text-center">Revolutionizing STEM Education Through Soccer</h2>
          <p className="text-xl text-center mb-12">
            footyLab combines professional-grade sports analytics with hands-on STEM learning, making data science accessible and exciting for youth aged 10-18.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="text-center">
              <h3 className="text-2xl font-semibold mb-4">Unique Approach</h3>
              <p>Experience real-world data science using professional soccer analytics tools</p>
            </div>
            <div className="text-center">
              <h3 className="text-2xl font-semibold mb-4">Inclusive Learning</h3>
              <p>Making STEM accessible to all, with a focus on underserved communities</p>
            </div>
            <div className="text-center">
              <h3 className="text-2xl font-semibold mb-4">Future-Ready Skills</h3>
              <p>Develop critical thinking and problem-solving abilities crucial for STEM careers</p>
            </div>
          </div>
        </div>
      </section>
      <MethodologySection />
      <TestimonialSection />
      <section className="py-16 bg-white">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold mb-8 text-center">Impact and Vision</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div>
              <h3 className="text-2xl font-semibold mb-4">Bridging the STEM Gap</h3>
              <p>footyLab is committed to increasing diversity in STEM fields by making data science education accessible and engaging for all youth, regardless of background.</p>
            </div>
            <div>
              <h3 className="text-2xl font-semibold mb-4">Beyond Soccer</h3>
              <p>While we start with soccer, our vision is to expand this innovative approach to other sports and physical activities, creating a comprehensive STEM education ecosystem.</p>
            </div>
          </div>
        </div>
      </section>
      <section className="py-16 bg-gray-300">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold mb-8 text-center">Trusted By</h2>
          <p className="text-center mb-8">
            We're proud to collaborate with these outstanding organizations to bring innovative STEM education to youth through sports.
          </p>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
            {partners.map((partner, index) => (
              <div key={index} className="flex items-center justify-center">
                <a 
                  href={partner.website} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="transition-opacity duration-300 hover:opacity-80"
                >
                  <img
                    src={partner.logo}
                    alt={partner.name}
                    className="max-h-32 max-w-full object-contain"
                  />
                </a>
              </div>
            ))}
          </div>
        </div>
      </section>
      <NewsSection />
      <section className="py-16 bg-navy text-yellow">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-8">Join the footyLab Revolution</h2>
          <p className="text-xl mb-8">Ready to transform STEM education in your community? Let's kick off the future of learning together!</p>
          <Button onClick={() => window.location.href='/contact'} className="text-xl py-3 px-6">
            Get Started with footyLab
          </Button>
        </div>
      </section>
    </PageLayout>
  );
};

export default Home;
