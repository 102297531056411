import React from 'react';
import PageLayout from '../components/templates/PageLayout';
import LazyImage from '../components/atoms/LazyImage';

const About = () => {
  return (
    <PageLayout>
      <div className="py-16 bg-white">
        <div className="container mx-auto px-4">
          <h1 className="text-4xl font-bold mb-8 text-center">About DataRook</h1>
          
          <section className="mb-16">
            <h2 className="text-3xl font-semibold mb-4">Our Mission</h2>
            <p className="text-xl mb-4">
              DataRook aims to revolutionize how young people engage with data science by making it relevant, exciting, and directly applicable to their interests. We're on a mission to empower youth through the intersection of sports and data science, fostering critical thinking skills and opening doors to STEM careers.
            </p>
            <p className="text-xl mb-4">
              Our vision is to become the leading provider of sports-integrated STEM education, democratizing access to data science skills for youth across the United States and beyond.
            </p>
          </section>

          <section className="mb-16">
            <h2 className="text-3xl font-semibold mb-6">Our Leadership Team</h2>
            
            <div className="flex flex-col md:flex-row items-center mb-12">
              <LazyImage
                src="/gus_profile.jpg"
                alt="Gustavo Alvarez-Suchini"
                className="w-48 h-48 rounded-full mb-4 md:mr-8"
              />
              <div>
                <h3 className="text-2xl font-semibold mb-2">Gustavo Alvarez-Suchini</h3>
                <p className="text-xl font-medium text-gray-700 mb-1">Founder & CEO</p>
                <p className="mb-4">
                  A computational physicist and PhD candidate in Learning, Design, and Technology at the University of Tennessee, Gustavo founded DataRook in 2022 with a vision to transform STEM education through sports. His background combines expertise in data analysis, youth soccer coaching, and educational product development. Gustavo is dedicated to creating pathways to STEM careers for underserved youth by connecting their passion for sports with cutting-edge data science education.
                </p>
              </div>
            </div>
            
            <div className="flex flex-col md:flex-row items-center">
              <LazyImage
                src="/derek_profile.png"
                alt="Derek Downey"
                className="w-48 h-48 rounded-full mb-4 md:mr-8"
              />
              <div>
                <h3 className="text-2xl font-semibold mb-2">Derek Downey</h3>
                <p className="text-xl font-medium text-gray-700 mb-1">Chief Technology Officer</p>
                <p className="mb-4">
                  Derek brings extensive enterprise-level experience from his time at Google to DataRook's technical development. With deep expertise in cloud infrastructure, data engineering, and scalable systems, Derek leads our technology strategy and platform development. His passion for using technology to create educational opportunities makes him an invaluable part of bringing footyLab's digital experience to life.
                </p>
              </div>
            </div>
          </section>

          <section className="mb-16">
            <h2 className="text-3xl font-semibold mb-4">footyLab: Where Soccer Meets Data Science</h2>
            <p className="mb-4">
              footyLab, our flagship program, is a groundbreaking after-school initiative that combines youth soccer with hands-on data science education. Using professional-grade analytics tools like Veo cameras and Catapult trackers, students collect and analyze their own performance data, learning crucial STEM skills in the process.
            </p>
            <ul className="list-disc list-inside mb-4">
              <li>Engage with real-world data science applications</li>
              <li>Learn Python programming and statistical analysis</li>
              <li>Develop critical thinking and problem-solving skills</li>
              <li>Explore careers in sports analytics and beyond</li>
            </ul>
          </section>

          <section className="mb-16">
            <h2 className="text-3xl font-semibold mb-4">Our Team and Advisors</h2>
            <p className="mb-4">
              Beyond our core leadership, we're building a world-class team of educators, technologists, and sports professionals. We're also establishing an advisory board with expertise in:
            </p>
            <ul className="list-disc list-inside mb-4">
              <li>Professional sports analytics</li>
              <li>STEM education policy</li>
              <li>EdTech entrepreneurship</li>
              <li>Youth sports organization management</li>
            </ul>
          </section>

          <section className="mb-16">
            <h2 className="text-3xl font-semibold mb-4">Addressing the STEM Education Gap</h2>
            <p className="mb-4">
              At DataRook, we're committed to increasing diversity in STEM fields. By making data science education accessible and engaging through soccer, we're reaching students who might not otherwise have access to quality STEM programs. Our focus on underserved communities is central to our mission of democratizing STEM education.
            </p>
          </section>

          <section>
            <h2 className="text-3xl font-semibold mb-4">Our Journey</h2>
            <ul className="timeline">
              <li className="mb-4">
                <strong>October 2022:</strong> DataRook founded by Gustavo Alvarez-Suchini
              </li>
              <li className="mb-4">
                <strong>2023:</strong> Development and pilot testing of footyLab program. <br></br>Our program was initially piloted as "Soccer Lab" with One Knoxville Collective. The success of this program, including partnerships with organizations like Girls Inc. in Oak Ridge, East Tennessee Freedom Schools, and the University of Tennessee demonstrated the potential of our unique approach to STEM education.
              </li>
              <li className="mb-4">
                <strong>2024:</strong> Official launch of footyLab in Knoxville, TN
              </li>
              <li>
                <strong>Future:</strong> Expansion to additional cities and states, development of online programs, and exploration of other sports integrations
              </li>
            </ul>
          </section>
        </div>
      </div>
    </PageLayout>
  );
};

export default About;
