import React from 'react';
import Button from '../atoms/Button';

const FundraisingBanner = () => {
  return (
    <div className="bg-gradient-to-r from-blue-600 to-indigo-700 text-white py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto flex flex-col md:flex-row md:items-center md:justify-between">
        <div className="mb-6 md:mb-0 md:pr-8">
          <h2 className="text-2xl font-bold mb-2">Help Fund STEM Innovation for Middle Schoolers!</h2>
          <p className="text-white/90 max-w-3xl">
            We're partnering with East Tennessee Freedom Schools to bring footyLab to their summer program! 
            Scholars will play soccer with GPS trackers and learn Python to analyze their own data.
          </p>
        </div>
        <div className="shrink-0">
          <a 
            href="https://secure.givelively.org/donate/east-tennessee-freedom-schools/freedom-schools-datarook-deliver-stem-with-soccer" 
            target="_blank" 
            rel="noopener noreferrer"
          >
            <Button className="whitespace-nowrap bg-white text-blue-700 hover:bg-blue-50 font-bold">
              Donate Now
            </Button>
          </a>
          <p className="text-xs mt-2 text-white/80 text-center">Tax-deductible 501(c)3 donation</p>
        </div>
      </div>
    </div>
  );
};

export default FundraisingBanner; 