import React from 'react';
import FeaturedArticle from '../molecules/FeaturedArticle';

const NewsSection = () => {
  const newsItems = [
    {
      title: "DataRook Wins Crowd Favorite at 'What's The Big Idea?' Pitch Competition",
      excerpt: "We're excited to announce that our Founder, Gustavo Alvarez-Suchini, won the Crowd Favorite award at Knoxville Entrepreneur Center's annual pitch competition!",
      link: "https://www.teknovation.biz/whats-the-big-idea-shines-a-light-on-six-promising-start-ups-in-the-knoxville-area/"
    },
    {
      title: "From Goals to Graphs: Presenting at UT Grand Challenges Summit",
      excerpt: "DataRook presented 'From Goals to Graphs: Bridging STEM Education Gaps with Soccer Analytics' at the University of Tennessee Grand Challenges Summit 2025.",
      link: "https://grandchallenges.tennessee.edu/2025-summit/schedule/"
    },
    {
      title: "Summer Program Partnership with East Tennessee Freedom Schools",
      excerpt: "DataRook is partnering with East Tennessee Freedom Schools to bring footyLab to their summer program, empowering middle school students through soccer and data science.",
      link: "https://secure.givelively.org/donate/east-tennessee-freedom-schools/freedom-schools-datarook-deliver-stem-with-soccer"
    },
    {
      title: "DataRook Featured in University of Tennessee News",
      excerpt: "Our innovative approach to STEM education through soccer analytics was recently highlighted in a University of Tennessee feature article.",
      link: "https://accolades.utk.edu/2024/09/25/kickstarting-futures/"
    }
  ];

  return (
    <section className="py-16 bg-gray-50">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-4">Recent News & Achievements</h2>
        <p className="text-lg text-gray-600 text-center mb-12 max-w-3xl mx-auto">
          Stay updated with DataRook's latest activities, partnerships, and community impact.
        </p>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {newsItems.map((item, index) => (
            <FeaturedArticle
              key={index}
              title={item.title}
              excerpt={item.excerpt}
              link={item.link}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default NewsSection; 