import React, { useEffect, useRef } from 'react';
import Button from '../atoms/Button';

const HeroSection = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play().catch(error => {
        console.log("Video playback failed:", error);
        if (videoRef.current) {
          videoRef.current.style.display = 'none';
          videoRef.current.parentNode.classList.add('video-fallback');
        }
      });
    }
  }, []);

  return (
    <section className="relative h-screen overflow-hidden">
      <video 
        ref={videoRef}
        className="absolute top-0 left-0 w-full h-full object-cover"
        autoPlay 
        loop 
        muted 
        playsInline
      >
        <source src="/footyLabPROMO2.mp4" type="video/mp4" />
      </video>
      <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
        <div className="text-center text-white z-10">
          <h1 className="text-4xl md:text-5xl font-bold mb-4">
            Empowering Youth Through the Science of Soccer
          </h1>
          <p className="mb-6">
          Where Data Analytics Meets the Beautiful Game
          </p>
          <Button onClick={() => window.location.href='/footylab'}>
            Learn More About footyLab
          </Button>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;