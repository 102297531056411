import React from 'react';

const MethodologySection = () => {
  const steps = [
    {
      id: 'play',
      title: 'Play',
      description: 'Students participate in soccer sessions wearing professional-grade GPS trackers to collect real-time performance data, just like elite athletes.',
      icon: '⚽',
    },
    {
      id: 'analyze',
      title: 'Analyze',
      description: 'Students learn Python programming and data analysis techniques to explore their own performance metrics and gain insights into their game.',
      icon: '📊',
    },
    {
      id: 'create',
      title: 'Create',
      description: 'Students build their own web dashboards to visualize their performance data, developing skills in data visualization and app development.',
      icon: '💻',
    }
  ];
  
  return (
    <section className="py-16 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-4">Our Unique Methodology</h2>
        <p className="text-lg text-gray-600 text-center mb-12 max-w-3xl mx-auto">
          footyLab combines physical activity with data science through our innovative three-step approach:
        </p>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {steps.map((step) => (
            <div key={step.id} className="bg-gray-50 rounded-xl p-8 text-center transform transition duration-300 hover:scale-105 hover:shadow-lg">
              <div className="text-5xl mb-4">{step.icon}</div>
              <h3 className="text-2xl font-bold mb-4">{step.title}</h3>
              <p className="text-gray-600">{step.description}</p>
            </div>
          ))}
        </div>
        
        <div className="mt-12 text-center">
          <p className="text-lg font-medium text-gray-700 max-w-3xl mx-auto">
            "I watched a middle-schooler transform from 'I hate math' to 'I love data science' by analyzing her own soccer stats."
          </p>
          <p className="text-sm text-gray-500 mt-2">- Gus Alvarez-Suchini, Founder</p>
        </div>
      </div>
    </section>
  );
};

export default MethodologySection; 